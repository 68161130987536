import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Crafting Technical Content`}</h1>
    <p>{`This section is not about writing - the Writing Guide in our Learn section will teach you everything
you need to know about our style, tone, and other content tricks. This section is all about how to
add automagic, ready-made React components to your files so that they really pop. We'll start with the basics:
buttons, Calls to Action (CTAs), images, headers etc. and gradually progress to passing your own props to
some of the more advanced components this site offers, all while remaining in a familiar markdown file.`}</p>
    <p>{`All of this is possible thanks the framework this site uses: `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby.`}</a>{` We're
able to leverage it's unique and modern features to build an incredibly fast, slick and easy-to-use
structure that many different people, with very different skills, and at many levels of technical
understanding can readily use, adapt, contribute to and extend.`}</p>
    <p>{`Writing markdown which renders responsive React components is a superpower. We hope you'll use it responsibly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      